import React from "react";
import PropTypes from "prop-types";
import InnerPageBanner from "components/inner_page_banner";
import { Link, graphql, StaticQuery } from "gatsby";
import CTABar1 from "components/cta_bar_1";
import LargeHeroBanner from "components/large_hero_banner";

const ServiceCard = ({ title, description, thumbnail_image, path }) => {
  const navigate = () => {
    window.location.href = path;
  };
  return (
    <div className="" onClick={navigate} style={{ cursor: "pointer" }}>
      <div
        className="service-layout2"
        onClick={navigate}
        style={{ cursor: "pointer" }}
      >
        <div style={{ textAlign: "center" }} className="service-layout5">
          <img
            src={`/${thumbnail_image}`}
            alt="service"
            className="img-fluid width-100 mb-3"
            style={{
              height: "200px",
              objectFit: "cover",
              cursor: "pointer",
              borderRadius: "10px",
              filter: "grayscale(100%)",
            }}
          />
          {/* <div className="item-icon">
                    <i className="flaticon-insurance-1" />
                </div> */}
          {/* <div style={{
                      backgroundImage: `/${thumbnail_image}`, 
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      maxHeight: "250px",
                      minHeight: "250px",
                      height: "250px"
                    }} alt="service" /> */}
        </div>
        <h3
          className="title title-medium size-xs color-dark-accent hover-color-accent text-center"
          style={{ cursor: "pointer" }}
        >
          <a href={path}>{title}</a>
        </h3>
        {/* <p style={{cursor: "pointer"}}>{description}</p> */}
        {/* <a href={path} className="btn-ghost btn-icon icon-right size-xs radius-4">Read More<i className="fas fa-caret-right" /></a> */}
      </div>
    </div>
  );
};

const ServicesPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <>
      <div id="header-area-space" className="slider-area bg-light-primary">
        <LargeHeroBanner bgImage={"/img/banner/car-road-wide.jpg"}>
          <h2>
            <strong>Services</strong>
          </h2>
          <br />
          <h2>Finstead provides a</h2>
          <h2>
            <strong>seamless and efficient process</strong>
          </h2>
          <h2>across all our services</h2>
          <br />
          <a
            href="/contact"
            className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
          >
            Enquire Now
            <i className="fas fa-angle-right" />
          </a>
        </LargeHeroBanner>
      </div>

      <CTABar1 />
      <br />
      <br />
      <br />
      <div className="w-100 text-center mb-4">
        <h2
          style={{ fontWeight: "300" }}
          className="title title-regular size-xs mt-5"
        >
          Our Services
        </h2>
      </div>
      <br />

      <div className="container mt-5 pt-5">
        <div
          className="rc-carousel nav-control-custom"
          data-loop="true"
          data-items={4}
          data-margin={30}
          data-autoplay="true"
          data-autoplay-timeout={5000}
          data-smart-speed={2000}
          data-dots="true"
          data-nav="false"
          data-nav-speed="false"
          data-r-x-small={2}
          data-r-x-small-nav="false"
          data-r-x-small-dots="true"
          data-r-x-medium={2}
          data-r-x-medium-nav="false"
          data-r-x-medium-dots="true"
          data-r-small={2}
          data-r-small-nav="false"
          data-r-small-dots="true"
          data-r-medium={4}
          data-r-medium-nav="false"
          data-r-medium-dots="true"
          data-r-large={4}
          data-r-large-nav="false"
          data-r-large-dots="true"
        >
          {posts &&
            posts.map((post) => <ServiceCard {...post.node.frontmatter} />)}
        </div>
        <br />
        <br />
        <div className="w-100 text-center">
          <p style={{ textAlign: "justify" }}>
            There are a range of strategies to solve finance and insurance
            challenges in businesses, and our team of professionals will assist
            you in this process by identifying products that best fit your
            businesses needs. We will simplify the process and help you navigate
            the range of product structures, terms, conditions and pricing.
            Simply put, our Finstead Professionals will do the heavy lifting for
            you, and we will work within your time frames and pricing
            requirements to deliver the best option in market.
          </p>
          <p style={{ textAlign: "justify" }}>
            We work across all types of lending and insurance solutions, with
            access to over 250 different credit and insurance products. A
            dedicated Finstead Professional will manage all aspects of these
            processes at every step of the way through to settlement. From Asset
            & Equipment Finance, Business Loans to Commercial Property and
            Construction Funding, we are your single trusted point of contact
            working with you to support your businesses growth.
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const query = () => (
  <StaticQuery
    query={graphql`
      query ServicesPageQuery {
        allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id

              frontmatter {
                path
                title
                description
                thumbnail_image
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ServicesPage data={data} count={count} />}
  />
);

export default query;
