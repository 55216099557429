import React from 'react';
import ServicesPage from 'paths/services_page';
import Layout from 'components/layout';
import FsAnalytics from 'components/fs_analytics';

const Services = () => {
    return (
        <Layout>
            {/* Finstead Analytics */}
            <FsAnalytics />
            <ServicesPage />
        </Layout>
    )
}

export default Services