import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const CTABar1 = () => {
    return (
        <section className="bg-accent100">
            <div className="container">
                <div className="row no-gutters call-to-action">
                    <div className="col-lg-9">
                        <ScrollAnimation animateIn="fadeInUp">
                            <div className="call-title">
                                <h2 className="title title-regular size-xs color-light-accent">Looking for some assistance?</h2>
                                <p className="color-light-accent">We're here to help. Get in touch with a Finstead Professional today for a no obligation discussion.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-3">
                        <div className="call-btn">
                            <a href="/contact" className="btn-ghost color-light radius-4 border-color-light btn-icon icon-light icon-right size-sm hover-bg-primary hover-border-primary">Enquire Now<i className="fas fa-angle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTABar1;